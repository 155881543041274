import autoTable from "jspdf-autotable";
import { calculateHeader, customFontFamily, dynamicHeader, footerConfig, headerConfig, printCertificateSection, tableConfiguration, tableConfigurationheaderandfooter, waterMarkImgConfig } from "./certificateConfig";
import { printcertificateConfigData, printCertificateConfiguration } from "./printcertificateConstant";
import { drawText } from "./commonTable";
import moment from "moment";

const LogoImageNew = "/images/WatermarkLogo.png";
const LogoImage = "/images/WatermarkLogo.png";
const ScanCopyImage = "/images/scancopy.png";
const DraftImage = "/images/draft.png";
const certificateHeaderNew = "/images/certificate_header.png";
const certificateHeader = "/images/certificate_header.png";
const certificateHeaderNonNabl = "/images/certificate_header_nonnabl.png";
const certificateHeaderIas = "/images/certificate_header_ias.png";
const certificateFooter = "/images/certificate_footer.png";
const stamp = "/images/stamp.png";

export const drawWaterMarkImg = () => {
  const imageUrl = printcertificateConfigData.letterHeadAndElectronicAndDraftConfig?.scanCopy && printcertificateConfigData
  .letterHeadAndElectronicAndDraftConfig?.letterHead
  ? ScanCopyImage
  : printcertificateConfigData
    .letterHeadAndElectronicAndDraftConfig?.letterHead
    ? printcertificateConfigData.letterHeadAndElectronicAndDraftConfig?.letterHeadCompnayType ? LogoImage : LogoImageNew
    : printcertificateConfigData.letterHeadAndElectronicAndDraftConfig?.draft
    ? DraftImage
    : null;
  try {
    const letterHeadWidth = waterMarkImgConfig.logoWidth;
    const letterHeadHeight = waterMarkImgConfig.logoHeight;
    const letterHeadXPosition =
      (printCertificateConfiguration.pageWidth - letterHeadWidth) / 2;
    const letterHeadYPosition =
      (printCertificateConfiguration.pageHeight - letterHeadHeight) / 2;

    const draftScanCopyWidth = 450; // Width for Draft and ScanCopy
    const draftScanCopyHeight = 500; // Height for Draft and ScanCopy
    const draftScanCopyXPosition =
      (printCertificateConfiguration.pageWidth - draftScanCopyWidth) / 2;
    const draftScanCopyYPosition =
      (printCertificateConfiguration.pageHeight - draftScanCopyHeight) / 2;

    // Add image logic
    if (
      printcertificateConfigData.letterHeadAndElectronicAndDraftConfig
        ?.letterHead &&
      printcertificateConfigData.letterHeadAndElectronicAndDraftConfig?.scanCopy
    ) {
      printCertificateConfiguration.pdfDoc.addImage(
        imageUrl,
        "PNG",
        draftScanCopyXPosition,
        draftScanCopyYPosition,
        draftScanCopyWidth,
        draftScanCopyHeight
      );
    } else if (
      printcertificateConfigData.letterHeadAndElectronicAndDraftConfig
        ?.letterHead
    ) {
      printCertificateConfiguration.pdfDoc.addImage(
        imageUrl,
        "PNG",
        letterHeadXPosition,
        letterHeadYPosition,
        letterHeadWidth,
        letterHeadHeight
      );
    } else if (
      printcertificateConfigData.letterHeadAndElectronicAndDraftConfig?.draft
    ) {
      printCertificateConfiguration.pdfDoc.addImage(
        imageUrl,
        "PNG",
        draftScanCopyXPosition,
        draftScanCopyYPosition,
        draftScanCopyWidth,
        draftScanCopyHeight
      );
    }
  } catch (error) {
    console.error("Error drawing water mark image:", error);
  }
};
export const drawLetterHeadHeaderImg = () => {
  let headerImage =
    printcertificateConfigData.certificateDetailsConfig?.calibrationType == 1
      ? printcertificateConfigData.letterHeadAndElectronicAndDraftConfig
          ?.letterHeadCompnayType
        ? certificateHeader
        : certificateHeaderNew
      : printcertificateConfigData.certificateDetailsConfig?.calibrationType ==
        2
      ? certificateHeaderIas
      : certificateHeaderNonNabl;

  try {
    if (
      printcertificateConfigData.letterHeadAndElectronicAndDraftConfig
        ?.letterHead
    ) {
      const scaledWidth = printCertificateConfiguration.pageWidth;
      const scaledHeight = headerConfig.lettterImgHeight;
      printCertificateConfiguration.pdfDoc.addImage(
        headerImage,
        "PNG",
        0,
        0,
        scaledWidth,
        scaledHeight
      );
    }
  } catch (error) {
    console.error("Error drawing header image:", error);
  }
};
export const drawLetterFooterImg = () => {
  try {
    if (
      printcertificateConfigData.letterHeadAndElectronicAndDraftConfig
        ?.letterHead  && footerConfig?.footerimgRemove
    ) {
      const scaledWidth = printCertificateConfiguration.pageWidth;
      // Draw the image on the page
      printCertificateConfiguration.pdfDoc.addImage(
        certificateFooter,
        "PNG",
        0,
        printCertificateConfiguration.pageHeight -
          footerConfig.footerimgHeight +
          2,
        scaledWidth,
        footerConfig.footerimgHeight
      );
    }
  } catch (error) {
    console.error("Error drawing footer image:", error);
  }
};

export const calibrationCertiifcateHeding = () => {
  const text = "CALIBRATION CERTIFICATE";
  const fontSize = 13;
  let yPos = calculateHeader.headerCALIBRATIONCERTIFICATExpostion + 5;
  printCertificateConfiguration.pdfDoc.setFont(
    customFontFamily?.boldFont,
    customFontFamily?.bold
  );
  printCertificateConfiguration.pdfDoc.setFontSize(fontSize);
  const textWidth = printCertificateConfiguration.pdfDoc.getTextWidth(text);
  const xPos = (printCertificateConfiguration.pdfDoc.internal.pageSize.width - textWidth) / 2;
  printCertificateConfiguration.pdfDoc.text(text, xPos, yPos);
};

export const drawDynamicHeader = () => {
  let localY;
  if (headerConfig.headerCALIBRATIONCERTIFICATE) {
    calibrationCertiifcateHeding();
    localY = calculateHeader.headerCALIBRATIONCERTIFICATExpostionafter;
  } else {
    localY = calculateHeader.headerCALIBRATIONCERTIFICATExpostion;
  }

  let calType = printcertificateConfigData.certificateDetailsConfig?.calibrationType;
  const panels = dynamicHeader(
    printcertificateConfigData.certificateDetailsConfig,calType
  );
  let tableRows = [];
  if(panels?.firstcolheadersecondcolvalue) {   
    panels.rows.forEach(row => {
      const leftKey = row[0]?.keys || "";
      const leftValue = row[0]?.values || "";
      const rightKey = row[1]?.keys || "";
      const rightValue = row[1]?.values || "";
      tableRows.push([
        {
          content: String(leftKey),
          styles: {
            font: customFontFamily?.boldFont,
            fontStyle: customFontFamily?.bold,
            halign: "left",
            valign: "middle",
          },
        },
        {
          content: panels.middleColumn && String(leftValue) ? `: ${String(leftValue)}` : String(leftValue),
          styles: { halign: "left", valign: "middle" },
        },
        {
          content: String(rightKey),
          styles: {
            font: customFontFamily?.boldFont,
            fontStyle: customFontFamily?.bold,
            halign: "left",
            valign: "middle",
          },
        },
        {
          content: panels.middleColumn && String(rightValue)  ? `: ${String(rightValue)}` : String(rightValue),
          styles: { halign: "left", valign: "middle" },
        },
      ]);
    });
  }
  if (panels?.mergeKeyValue) {
    const row = [];
    panels.headerKeysAndValues.forEach(item => {
      row.push({
        content: `${item.keys}: ${item.values}`,
        styles: { halign: "center", valign: "middle", font: customFontFamily?.boldFont }
      });
    });

    tableRows.push(row);
  }
  if (panels?.firstrowHeaderscondValue) {   
    const headerRow = [];
    const valueRow = [];

    panels.rows.forEach(row => {
      const leftKey = row[0]?.keys || "";
      const leftValue = row[0]?.values || "";
      const rightKey = row[1]?.keys || "";
      const rightValue = row[1]?.values || "";

      if (leftKey && leftValue) {
      headerRow.push({
        content: leftKey,
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "center",
          valign: "middle",
        },
      });
        valueRow.push({
          content: panels.middleColumn && String(leftValue) ? `: ${String(leftValue)}` : String(leftValue),
          styles: { halign: "center", valign: "middle" },
        });
      }
      if (rightKey && rightValue) {
      headerRow.push({
        content: rightKey,
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "center",
          valign: "middle",
        },
      });
     valueRow.push({
       content: panels.middleColumn && String(rightValue) ? `: ${String(rightValue)}` : String(rightValue),
        styles: { halign: "center", valign: "middle" },
      });
      }
    });

  // Add header and value rows only if they contain data
  if (headerRow.length > 0 && valueRow.length > 0) {
    tableRows.push(headerRow);
    tableRows.push(valueRow);
    }
  }
  // Draw the table using autoTable
  autoTable(printCertificateConfiguration.pdfDoc, {
    startY: localY,
    margin: tableConfigurationheaderandfooter?.margins,
    body: tableRows,
    theme: "grid",
    headStyles: {
      fillColor: false,
      textColor: printCertificateConfiguration.textColor,
      fontSize: headerConfig.tableRowfontSizes,
      font: customFontFamily?.boldFont,
    },
    styles: {
      fillColor: false,
      textColor: printCertificateConfiguration.textColor,
      lineColor: printCertificateConfiguration.textColor,
      lineWidth: printCertificateConfiguration.lineWidth,
      fontSize: headerConfig.tableRowfontSizes,
      cellPadding: headerConfig.cellPadding,
      font: customFontFamily?.normalFont,
    },
  });
};

export const drawDynamicFooter = () => {
  if (printCertificateSection.jrpmtable) {
    let img = null;
    if (
      printcertificateConfigData.footerSignSettingConfig?.signImg &&
      printcertificateConfigData.footerSignSettingConfig?.approvedby?.[5]
    ) {
      img = printcertificateConfigData.footerSignSettingConfig?.approvedby?.[5];
    }
    let tableRows = [];
    let row = [
      {
        content: "Calibrated By",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "left",
        },
        colSpan: 2,
      },
      {
        content: "Reviewed & Approved By | Authorized Signatory",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "left",
        },
        colSpan: 2,
      },
      { content: "", rowSpan: 2 },
    ];
    tableRows.push(row);
    // Define the second row
    row = [
      {
        content: "Identification",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "left",
        },
      },
      {
        content:
          printcertificateConfigData.footerSignSettingConfig?.calibratedby?.[1],
        styles: { halign: "left" },
      },
      {
        content: "Identification",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "left",
        },
      },
      {
        content:
          printcertificateConfigData.footerSignSettingConfig?.approvedby?.[1],
        styles: { halign: "left" },
      },
    ];
    tableRows.push(row);

    // Calculate the total available width of the table
    const totalPageWidth =
      printCertificateConfiguration.pageWidth -
      tableConfiguration?.margins.right * 2;
    const columnPercentages = [15, 25, 15, 25, 24];
    // Calculate the actual column widths based on percentages
    const columnWidths = columnPercentages.map((percentage) => {
      return (totalPageWidth * percentage) / 100;
    });

    // Draw the table using autoTable
    autoTable(printCertificateConfiguration.pdfDoc, {
      startY:
        printCertificateConfiguration.pageHeight -
        footerConfig.footerimgHeight -
        50,
      margin: tableConfigurationheaderandfooter?.margins,
      body: tableRows,
      theme: "grid",
      styles: {
        fillColor: false,
        textColor: printCertificateConfiguration.textColor,
        lineColor: printCertificateConfiguration.textColor,
        lineWidth: printCertificateConfiguration.lineWidth,
        fontSize: printCertificateConfiguration.fontSizes.h2,
        halign: "center",
        font: customFontFamily?.normalFont,
      },
      columnStyles: {
        0: { cellWidth: columnWidths[0] },
        1: { cellWidth: columnWidths[1] },
        2: { cellWidth: columnWidths[2] },
        3: { cellWidth: columnWidths[3] },
        4: { cellWidth: columnWidths[4] },
      },
      didDrawCell: (data) => {
        if (data.column.index === 4 && img) {
          const { x, y, height, width } = data.cell;
          const imageWidth = 60; // Adjust the image width
          const imageHeight = 30; // Adjust the image height
          const centerX = x + (width - imageWidth) / 2;
          // Add the image at the correct position
          printCertificateConfiguration.pdfDoc.addImage(
            img,
            "PNG",
            centerX,
            y + (height - imageHeight) / 2,
            imageWidth,
            imageHeight
          );
        }
      },
    });
  } else {
    let footers = printcertificateConfigData.footerSignSettingConfig.roles;
    let certifcateviewDetails =
      printcertificateConfigData.certificateDetailsConfig;
    let certificateSetting =
      printcertificateConfigData.letterHeadAndElectronicAndDraftConfig;
    let footerData = printcertificateConfigData.footerSignSettingConfig;

    if(footerConfig.stampui){
      footers = [
        ...footers,
        {
          name: "",
          id: 3,
          by: "",
          label: " ",
          align: "center",
        },
      ];
    }

    footers.map((e, i) => {
      if (e.id == 1) {
        footers[i].imgSrc = footerData.calibratedby[5];
        footers[i].by = footerData.calibratedby[1];
        footers[i].date = certifcateviewDetails.calibrationDate;
        footers[i].companyName = footerData.calibratedby[3];
        footers[i].label = footerData.organizationLables?.[0]
          ? footerData.organizationLables[0]?.name
          : "Calibrated By";
        // footers[i].name = footerData.calibratedby[4]
        //   ? footerData.calibratedby[4]
        //   : footers[i].name;
        footers[i].name = "Calibration Technician";
      } else if (e.id == 2) {
        footers[i].imgSrc = footerData.approvedby[1] ? "https://labfix.bfiinfotech.co.in/api/fileUpload/uploads/1737034906__Seshum.png" : "";
        footers[i].by = footerData.approvedby[1] ? "Seshum Naidoo" : "";
        // footers[i].imgSrc = footerData.approvedby[5];
        // footers[i].by = footerData.approvedby[1];
        footers[i].date = certifcateviewDetails.approvedDate;
        footers[i].companyName = footerData.approvedby[3];
        footers[i].label = footerData.organizationLables?.[1]
          ? footerData.organizationLables[1]?.name
          : "Technical Signatory";
        // footers[i].name = footerData.approvedby[4]
        //   ? footerData.approvedby[4]
        //   : footers[i].name;
        footers[i].name = "Metrologist";
      } else if (e.id == 3) {
        footers[i].imgSrc = footerData.reviewedBy[4];
        footers[i].by = footerData.reviewedBy[1];
        footers[i].date = certifcateviewDetails.reviewedDate;
        footers[i].companyName = footerData.reviewedBy[3];
        footers[i].name = certifcateviewDetails.companyName;
      } else if (e.id == 4) {
        footers[i].imgSrc = footerData.signedBy[4];
        footers[i].by = footerData.signedBy[1];
        footers[i].date = certifcateviewDetails.signedDate;
        footers[i].companyName = footerData.signedBy[3];
        footers[i].name = certifcateviewDetails.companyName;
      } else if (e.id == 5) {
        footers[i].imgSrc = footerData.witnessedBy[4];
        footers[i].by = footerData.witnessedBy[1];
        footers[i].date = certifcateviewDetails.witnessDate;
        footers[i].companyName = footerData.witnessedBy[3];
        footers[i].name = certifcateviewDetails.companyName;
      }
    });

    const tableRows = [];
    if (footerConfig.stampui) {
      const index1 = footers.findIndex((item) => item.id == "2");
      const index2 = footers.findIndex((item) => item.id == "3");
      // Swap the elements
      [footers[index1], footers[index2]] = [footers[index2], footers[index1]];

      // Prepare a single row for column-wise data
      const footerRow = footers.map((column, index) => ({
        content: column.label || "\u00A0", // Add label content
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "left",
        },
        rowSpan: index == 1 ? certificateSetting.signImg ? 4 : 3 : 1,
      }));
      const nameRow = footers.map((column, index) => ({
        content: index === 1 ? footers[2]?.name : column.name || "\u00A0",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "left",
        },
      }));

      let electronicSigns = certificateSetting.electronicSign;

      const combinedRow = footers.map((column, index) => ({
        content:
          index === 1
            ? `${
                footers[2]?.by && electronicSigns
                  ? "Electronically Signed By\n"
                  : ""
              }${footers[2]?.by || ""}${
                footers[2]?.date && electronicSigns
                  ? "\n" + moment(footers[2]?.date).format("DD/MM/YYYY, HH:mm")
                  : "\u00A0"
              }`
            : `${
                column.by && electronicSigns ? "Electronically Signed By\n" : ""
              }${column.by || ""}${
                column.date && electronicSigns
                  ? "\n" + moment(column.date).format("DD/MM/YYYY, HH:mm")
                  : "\u00A0"
              }`,

        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "left",
        },
      }));

      // Push these rows into tableRows
      if (footerRow.length > 0) {
        tableRows.push(footerRow);
      }
      if (combinedRow.length > 0) {
        tableRows.push(combinedRow);
      }
      if (nameRow.length > 0) {
        tableRows.push(nameRow);
      }

      if (certificateSetting.signImg) {
        const imageRow = footers.map((column,index) => ({
          content: "", 
          // content: index === 1 ? footers[2]?.name
          imgSrc: index === 1 ? footers[2]?.imgSrc : column.imgSrc,
          styles: {
            font: customFontFamily?.boldFont,
            fontStyle: customFontFamily?.bold,
            halign: "center",
            minCellHeight: 30,
          },
        }));

        // Insert the image row before the last row
        if (imageRow.length > 0) {
          tableRows.splice(tableRows.length - 1, 0, imageRow);
        }
      }

    } else {
      // Check if there are any footers to process
      if (footers?.length > 0) {
        // Add header row if necessary
        const headerRow = [];
        const bfiColumns = footers.filter(
          (item) => item.id === "1" || item.id === "2"
        );
        const customerColumns = footers.filter(
          (item) => item.id === "3" || item.id === "4" || item.id === "5"
        );
        if (bfiColumns.length > 0) {
          headerRow.push({
            content: footerConfig?.footerCompanyNameFirstRow,
            colSpan: bfiColumns.length,
            styles: {
              font: customFontFamily?.boldFont,
              fontStyle: customFontFamily?.bold,
              halign: "center",
            },
          });
        }
        if (customerColumns.length > 0) {
          headerRow.push({
            content: "For Customer",
            colSpan: customerColumns.length,
            styles: {
              font: customFontFamily?.boldFont,
              fontStyle: customFontFamily?.bold,
              halign: "center",
            },
          });
        }
        // if (headerRow.length > 0) {
        //   tableRows.push(headerRow);
        // }

        // Prepare a single row for column-wise data
        const footerRow = footers.map((column,i ) => ({
          content: column.label || "\u00A0", // Add label content
          styles: {
            font: customFontFamily?.boldFont,
            fontStyle: customFontFamily?.bold,
            halign: i === 0 ? "left" : (i === footers.length - 1 ? "right" : "center"),
          },
        }));

        const nameRow = footers.map((column) => ({
          content: column.name || "\u00A0", // Add name content
          styles: {
            font: customFontFamily?.boldFont,
            fontStyle: customFontFamily?.bold,
            halign: "left",
          },
        }));

        let electronicSigns = certificateSetting.electronicSign;

        const combinedRow = footers.map((column) => ({
          content: `${
            column.by && electronicSigns ? "Electronically Signed By\n" : ""
          }${column.by || ""}${
            column.date && electronicSigns
              ? "\n" + moment(column.date).format("DD/MM/YYYY, HH:mm")
              : "\u00A0"
          }`,
          styles: {
            font: customFontFamily?.boldFont,
            fontStyle: customFontFamily?.bold,
            halign: "left",
          },
        }));

        // Push these rows into tableRows
        if (footerRow.length > 0) {
          tableRows.push(footerRow);
        }
        if (combinedRow.length > 0) {
          tableRows.push(combinedRow);
        }
        if (nameRow.length > 0) {
          tableRows.push(nameRow);
        }

        // Add a second-last row with images
        if (certificateSetting.signImg) {
          const imageRow = footers.map((column) => ({
            content: "", // Content remains empty; image will be drawn using didDrawCell
            imgSrc: column.imgSrc, // Pass the image source for later use
            styles: {
              font: customFontFamily?.boldFont,
              fontStyle: customFontFamily?.bold,
              halign: "center",
              minCellHeight: 30,
            },
          }));

          // Insert the image row before the last row
          if (imageRow.length > 0) {
            tableRows.splice(tableRows.length - 1, 0, imageRow);
          }
        }
      }
    }

    let formattedTableRows = tableRows.map((row, rowIndex) => 
      row.map((column, columnIndex) => {
        const halign =
          columnIndex === 0 // First column
            ? "left"
            : columnIndex === row.length - 1 // Last column
            ? "right"
            : footerConfig.stampui || rowIndex === 0 // Middle columns
            ? "right"
            : "center"; // Middle columns for non-stampui and non-header rows
    
        return rowIndex === 0 && !footerConfig.stampui // Keep the first row unchanged for non-stampui
          ? column
          : {
              ...column,
              styles: {
                ...column.styles,
                halign,
              },
            };
      })
    );    


    // column width prepare
    const numberOfColumns = footers.length;
    const totalWidth =
      printCertificateConfiguration.pdfDoc.internal.pageSize.getWidth() - 29;
    const columnWidth = totalWidth / numberOfColumns;

    // Generate table with autoTable
    autoTable(printCertificateConfiguration.pdfDoc, {
      startY:
        printCertificateConfiguration.pageHeight -
        footerConfig.footerimgHeight -
        80,
      margin: tableConfigurationheaderandfooter?.margins,
      body: formattedTableRows,
      theme: "grid",
      styles: {
        fillColor: false,
        textColor: printCertificateConfiguration.textColor,
        lineColor: printCertificateConfiguration.textColor,
        lineWidth: printCertificateConfiguration.lineWidth,
        fontSize: printCertificateConfiguration.fontSizes.h2,
        halign: "center",
        font: customFontFamily?.normalFont,
        cellPadding: !footerConfig.stampui && certificateSetting.signImg ? { top: 1, left: 5, bottom: 1, right: 5 } : { top: 3, left: 5, bottom: 3, right: 5 },
      },
      columnStyles: {
        // Set equal width for each column
        0: { cellWidth: columnWidth },
        1: { cellWidth: columnWidth },
        2: { cellWidth: columnWidth },
        3: { cellWidth: columnWidth },
        4: { cellWidth: columnWidth },
      },
      didDrawCell: (data) => {
        if (data.column.index === 1 && footerConfig.stampui && certificateSetting?.letterHead) {
          const { x, y, width, height } = data.cell;
          const imageSize = height;
          const centerX = x + (width - imageSize) / 2;
          const centerY = y + (height - imageSize) / 2;
        
          printCertificateConfiguration.pdfDoc.addImage(
            stamp,
            "PNG",
            centerX,
            centerY,
            imageSize,
            imageSize
          );
        }
        
        const { section } = data;
        if (section === "body" && certificateSetting.signImg) {
          const cell = data.cell;
          const rowIndex = data.row.index;
          const columnIndex = data.column.index;
          // Check if this cell corresponds to the imageRow
          if (rowIndex === tableRows.length - 2) {
            const imgSrc = footers[columnIndex]?.imgSrc;
            if (imgSrc) {
              const imgWidth = 60;
              const imgHeight =  cell.height - 10;
              let x  ;
              const y = cell.y + 5;

              if (columnIndex === 0) {
                x = cell.x + 5;
              } else if (columnIndex === tableRows[0].length - 1) {
                x = cell.x + cell.width - imgWidth - 5;
              } else {
                x = cell.x + (cell.width - imgWidth) / 2;
              }
              // Add the image to the PDF
              printCertificateConfiguration.pdfDoc.addImage(
                imgSrc,
                "PNG",
                x,
                y,
                imgWidth,
                imgHeight
              );
            }
          }
        }
      },
    });
  }
};
