import autoTable from "jspdf-autotable";
import {
  calculateHeader,
  customFontFamily,
  footerConfig,
  headerConfig,
  tableConfigurationheaderandfooter,
} from "./datasheetConfig";
import {
  printdatasheetConfigData,
  printDatasheetConfiguration,
} from "./datasheetConstant";
import { drawText } from "./commonTable";
import {
  DataisValid,
  DateisValid,
} from "../../../utils/components/accuracyandlcandrangesshow";

const datasheetHeaderNew = "/images/certificate_header.png";
const DraftImage = "/images/draft.png";
const datasheetHeader = "/images/certificate_header.png";
const datasheetHeaderNonNabl = "/images/certificate_header_nonnab.png";
const datasheetHeaderIas = "/images/certificate_header_ias.png";
const datasheetFooter = "/images/certificate_footer.png";
const Logo = "./images/logo.png";

export const drawWaterMarkImg = () => {
  const imageUrl = DraftImage;
  try {  
    const draftScanCopyWidth = 360; // Width for Draft and ScanCopy
    const draftScanCopyHeight = 466; // Height for Draft and ScanCopy
    const draftScanCopyXPosition =
      (printDatasheetConfiguration.pageWidth - draftScanCopyWidth) / 2;
    const draftScanCopyYPosition =
      (printDatasheetConfiguration.pageHeight - draftScanCopyHeight) / 2;

    // Add image logic
    if (
      !printdatasheetConfigData.datasheetContextData?.datasheetApprovedBy
    ) {
      printDatasheetConfiguration.pdfDoc.addImage(
        imageUrl,
        "PNG",
        draftScanCopyXPosition,
        draftScanCopyYPosition,
        draftScanCopyWidth,
        draftScanCopyHeight
      );
    }
  } catch (error) {
    console.error("Error drawing water mark image:", error);
  }
};

export const drawLetterHeadHeaderImg = () => {
  let headerImage =
    printdatasheetConfigData?.datasheetContextData?.datasheetDetails
      ?.calibrationType == 1
      ? printdatasheetConfigData.letterHeadAndElectronicAndDraftConfig
          ?.letterHeadCompnayType
        ? datasheetHeader
        : datasheetHeaderNew
      : printdatasheetConfigData?.datasheetContextData?.datasheetDetails
          ?.calibrationType == 2
      ? datasheetHeaderIas
      : datasheetHeaderNonNabl;

  try {
    if (
      printdatasheetConfigData.letterHeadAndElectronicAndDraftConfig?.letterHead
    ) {
      const scaledWidth = printDatasheetConfiguration.pageWidth;
      const scaledHeight = headerConfig.lettterImgHeight;
      printDatasheetConfiguration.pdfDoc.addImage(
        headerImage,
        "PNG",
        0,
        0,
        scaledWidth,
        scaledHeight
      );
    }
  } catch (error) {
    console.error("Error drawing header image:", error);
  }
};
export const drawLetterFooterImg = () => {
  try {
    if (
      printdatasheetConfigData.letterHeadAndElectronicAndDraftConfig?.letterHead
    ) {
      const scaledWidth = printDatasheetConfiguration.pageWidth;
      // Draw the image on the page
      printDatasheetConfiguration.pdfDoc.addImage(
        datasheetFooter,
        "PNG",
        0,
        printDatasheetConfiguration.pageHeight -
          footerConfig.footerimgHeight +
          2,
        scaledWidth,
        footerConfig.footerimgHeight
      );
    }
  } catch (error) {
    console.error("Error drawing footer image:", error);
  }
};
export const drawDynamicHeader = () => {
  let localY;
    localY = calculateHeader.headerCALIBRATIONDATASHEETExpostion;

  const tableRows = [
    [
      {
        content: "",
        colSpan: 1,
        rowSpan: 2, // rowspan 5 for the image
        styles: { halign: "center", valign: "middle" },
      },
      {
        content:
         `${footerConfig?.footerCompanyNameFirstRow}\nObservation Sheet - ` +
          (printdatasheetConfigData.datasheetContextData?.referenceData
            ?.srfInstruments?.requestedDucName ||
            printdatasheetConfigData.datasheetContextData.datasheetDetails
              ?.requestedname ||
            printdatasheetConfigData.datasheetContextData.datasheetDetails
              ?.instrumentName),
        colSpan: 1,
        rowSpan: 2, // rowspan 5 for the image
        styles: {
          halign: "center",
          valign: "middle",
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
        },
      },
      {
        content: "Document Number",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "left",
          valign: "middle",
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
        },
      },
      {
        content: DataisValid(
          printdatasheetConfigData.datasheetContextData?.instrumentDetails
            ?.datasheetFormatNumber
        ),
        styles: { halign: "left", valign: "middle" },
      },
    ],
    [
      {
        content: "Issue No. / Date",
        styles: {
          fontStyle: "bold",
          halign: "left",
          valign: "middle",
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
        },
      },
      {
        content:
          DataisValid(
            printdatasheetConfigData.datasheetContextData?.instrumentDetails
              ?.DatasheetRevisionNumber
          ) +
          " / " +
          DateisValid(
            printdatasheetConfigData.datasheetContextData?.instrumentDetails
              ?.DatasheetRevisionDate
          ),
        styles: { halign: "left", valign: "middle" },
      },
    ],
  ];
  // Calculate the total available width of the table
  const totalPageWidth =
    printDatasheetConfiguration.pageWidth -
    tableConfigurationheaderandfooter?.margins.right * 2;
  const columnPercentages = [20, 38, 20, 22];
  // Calculate the actual column widths based on percentages
  const columnWidths = columnPercentages.map((percentage) => {
    return (totalPageWidth * percentage) / 100;
  });
  // Draw the table using autoTable
  autoTable(printDatasheetConfiguration.pdfDoc, {
    startY: localY,
    margin: tableConfigurationheaderandfooter?.margins,
    body: tableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printDatasheetConfiguration.textColor,
      lineColor: printDatasheetConfiguration.textColor,
      lineWidth: printDatasheetConfiguration.lineWidth,
      fontSize: headerConfig.tableRowfontSizes,
      halign: "center",
      cellPadding: headerConfig.cellPadding,
      font: customFontFamily?.normalFont,
    },
    didDrawCell: (data) => {
      const { section } = data;
      if (section === "body") {
        const cell = data.cell;
        const columnIndex = data.column.index;

        if (columnIndex === 0) {
          const imgSrc = Logo;

          const maxImgWidth = cell.width * 0.9; // Image width is 80% of cell width
          const maxImgHeight = cell.height * 0.9; // Image height is 80% of cell height

          // Maintain aspect ratio
          const imgAspectRatio = 1; // Assuming square logo, adjust if different
          let imgWidth = maxImgWidth;
          let imgHeight = imgWidth / imgAspectRatio;

          if (imgHeight > maxImgHeight) {
            imgHeight = maxImgHeight;
            imgWidth = imgHeight * imgAspectRatio;
          }

          // Calculate position to center the image
          const x = cell.x + (cell.width - imgWidth) / 2;
          const y = cell.y + (cell.height - imgHeight) / 2;

          // Add the image to the PDF
          printDatasheetConfiguration.pdfDoc.addImage(
            imgSrc,
            "PNG",
            x,
            y,
            imgWidth,
            imgHeight
          );

          // Optional: Draw a border around the image
          printDatasheetConfiguration.pdfDoc.setLineWidth(0.1);
          printDatasheetConfiguration.pdfDoc.setDrawColor(0, 0, 0);
          printDatasheetConfiguration.pdfDoc.rect(
            cell.x,
            cell.y,
            cell.width,
            cell.height
          );
        }
      }
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
    },
  });

  localY = printDatasheetConfiguration.pdfDoc.lastAutoTable.finalY + 10;

  drawText("OBSERVATION SHEET", localY, localY, "center", 14, 5, 0);

  const newTableRows = [
    [
      {
        content: "Purpose",
        styles: {
          halign: "left",
          valign: "middle",
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
        },
      },
      {
        content: "Calibration",
        styles: { halign: "left", valign: "middle" },
      },
      {
        content: "Job No",
        styles: {
          halign: "left",
          valign: "middle",
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
        },
      },
      {
        content:
          printdatasheetConfigData.datasheetContextData?.certificateDetails
            ?.certificateNumber,
        styles: { halign: "left", valign: "middle" },
      },
      {
        content: "Date of Calibration",
        styles: {
          halign: "left",
          valign: "middle",
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
        },
      },
      {
        content: DateisValid(
          printdatasheetConfigData.datasheetContextData?.datasheetDetails
            ?.calibrationDate
        ),
        styles: { halign: "left", valign: "middle" },
      },
    ],
  ];

 const columnPercentagesTable2 = [10,15,10,30,20,15];
 const columnWidthsTable2 = columnPercentagesTable2.map((percentage) => {
   return (totalPageWidth * percentage) / 100;
 });

  // Draw the new table with 6 columns
  autoTable(printDatasheetConfiguration.pdfDoc, {
    startY: localY + 10,
    margin: tableConfigurationheaderandfooter?.margins,
    body: newTableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printDatasheetConfiguration.textColor,
      lineColor: printDatasheetConfiguration.textColor,
      lineWidth: printDatasheetConfiguration.lineWidth,
      fontSize: headerConfig.tableRowfontSizes,
      halign: "center",
      cellPadding: headerConfig.cellPadding,
      font: customFontFamily?.normalFont,
    },    
    columnStyles: {
      0: { cellWidth: columnWidthsTable2[0] },
      1: { cellWidth: columnWidthsTable2[1] },
      2: { cellWidth: columnWidthsTable2[2] },
      3: { cellWidth: columnWidthsTable2[3] },
      4: { cellWidth: columnWidthsTable2[4] },
      5: { cellWidth: columnWidthsTable2[5] },
    },
  });
};

export const drawDynamicFooter = () => {
  let datsheetSetting =
    printdatasheetConfigData.letterHeadAndElectronicAndDraftConfig;
  let datasheetApprovedby = printdatasheetConfigData.datasheetContextData?.datasheetApprovedBy || "";
  let LabelRow = ["Calibrated By", "Approved By"];
  let RoleRow = [
    printdatasheetConfigData.footerSignSettingConfig?.calibratedBy,
    // datasheetApprovedby,
    "Seshum Naidoo",
  ];
  let electronicallyRow = [
    "Electronically signed by",
    datasheetApprovedby ? "Electronically signed by" : "",
  ];
  let DateRow = [
    DateisValid(
      printdatasheetConfigData.datasheetContextData?.datasheetDetails
        ?.calibrationDate,"DD/MM/yyyy hh:mm A"
    ),
    DateisValid(
      printdatasheetConfigData.datasheetContextData?.datasheetDetails
        ?.datasheetApprovedDate,"DD/MM/yyyy hh:mm A"
    ),
  ];

  let signimgRow = [
    printdatasheetConfigData.datasheetContextData?.calibratedByImgSign,
    // printdatasheetConfigData.datasheetContextData?.datasheetApprovedByImgSign,
    "https://labfix.bfiinfotech.co.in/api/fileUpload/uploads/1737034906__Seshum.png",
  ];

  const tableRows = [];

  tableRows.push([
    {
      content: footerConfig?.footerCompanyNameFirstRow,
      colSpan: LabelRow.length, // Span across both columns
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "center", // Center align the text
        valign: "middle", // Vertically align the text
      },
    },
  ]);

  const combinedRows = LabelRow.map((content, index) => {
    return {
      content: datsheetSetting?.electronicSign
        ? `${content}\n${electronicallyRow[index]}\n${RoleRow[index]}\n${DateRow[index]}`
        : `${content}\n\n\n`,
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "center",
        valign: "middle",
      },
    };
  });

  // // Add Row1 to tableRows
  // tableRows.push(
  //   Row1.map((content) => ({
  //     content,
  //     styles: {
  //       font: customFontFamily?.boldFont,
  //       fontStyle: customFontFamily?.bold,
  //       halign: "center",
  //     },
  //   }))
  // );

  tableRows.push(combinedRows);

  let formattedTableRows = tableRows.map((row, rowIndex) => {
    // Skip processing for the first row
    if (rowIndex === 0) {
      return row;
    }

    return row.map((column, columnIndex) => ({
      ...column, // Keep all other properties of the column
      styles: {
        ...column.styles, // Preserve existing styles
        halign:
          columnIndex === 0 // First column
            ? "left"
            : columnIndex === row.length - 1 // Last column
            ? "right"
            : "center", // Default alignment for other cases
      },
    }));
  });

  // column width prepare
  const numberOfColumns = 2;
  const totalWidth =
    printDatasheetConfiguration.pdfDoc.internal.pageSize.getWidth() - 29;
  const columnWidth = totalWidth / numberOfColumns;

  // Generate table with autoTable
  autoTable(printDatasheetConfiguration.pdfDoc, {
    startY:
      printDatasheetConfiguration.pageHeight -
      footerConfig.footertableHeight -
      28,
    margin: tableConfigurationheaderandfooter?.margins,
    body: formattedTableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printDatasheetConfiguration.textColor,
      lineColor: printDatasheetConfiguration.textColor,
      lineWidth: printDatasheetConfiguration.lineWidth,
      fontSize: printDatasheetConfiguration.fontSizes.h2,
      halign: "center",
      font: customFontFamily?.normalFont,
      cellPadding: datsheetSetting?.signImg
        ? { top: 2, left: 5, bottom: 2, right: 5 }
        : { top: 3, left: 5, bottom: 3, right: 5 },
    },
    columnStyles: {
      // Set equal width for each column
      0: { cellWidth: columnWidth },
      1: { cellWidth: columnWidth },
    },
    didDrawCell: (data) => {
      const { section } = data;
      if (section === "body" && datsheetSetting?.signImg) {
        const cell = data.cell;
        const rowIndex = data.row.index;
        const columnIndex = data.column.index;
        // Check if this cell corresponds to the imageRow
        if (rowIndex === tableRows.length - 1) {
          const imgSrc = signimgRow[columnIndex];
          if (imgSrc && rowIndex == tableRows.length - 1) {
            const imgWidth = 60;
            const imgHeight = 30;
            let x  ;
            const y = cell.y + 10;

              if (columnIndex === 0) {
                x = cell.x + 5;
              } else if (columnIndex === tableRows[0].length - 1) {
                x = cell.x + (cell.width - imgWidth) / 2;
              } else {
                x = cell.x + cell.width - imgWidth - 5;
              }
            // Add the image to the PDF
            printDatasheetConfiguration.pdfDoc.addImage(
              imgSrc,
              "PNG",
              x,
              y,
              imgWidth,
              imgHeight
            );
          }
        }
      }
    },
  });
};
