import { DOMAIN, EccentricityDiagramInstruemntID } from "../../../global";
import { DateisValid, parseEnvironment } from "../../../utils/components/accuracyandlcandrangesshow";
import { customFontFamily, digitallySignConfig, environmentTableConfig, footerConfig, headerConfig, MasterEQPDetails, printCertificateSection, tableConfiguration, totalHeaderHeightWithCalibrationLableorNot, UUCDetails } from "./certificateConfig";
import { addNewPage } from "./printCertificate";
import { layoutConfig, parseValue, printcertificateConfigData, printCertificateConfiguration } from "./printcertificateConstant";
import autoTable from "jspdf-autotable";
import QRCode from "qrcode";

const checkmarkimg = "/images/checkmarksimg.png";

export const drawfirst2ColumnRowspan = async (panels) => {
  let tableRows = [];
  const rowSpan = panels?.rightPanel?.keys?.length;
  let row = [];
  
    if (panels?.firsttwocolumnrowSpan) {
      row.push({
        content: panels?.leftPanel.keys[0] + "\n\n" + panels?.leftPanel.values[0],
        rowSpan: rowSpan,
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          valign: "top",
          halign: "left",
        },
        colSpan: 2,
      });
    } else {
    row.push({
      content: panels?.leftPanel.keys[0],
      rowSpan: rowSpan,
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        valign: "middle",
        halign: "left",
      },
    });
    row.push({
      content: panels?.leftPanel.values[0],
      rowSpan: rowSpan,
      styles: { valign: "middle", halign: "left" },
    });
  }

    row.push({
      content: panels?.rightPanel.keys[0],
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        valign: "middle",
        halign: "left",
      },
    });

    row.push({
      content: panels?.rightPanel.values[0],
      styles: { valign: "middle", halign: "left" },
    });
  tableRows.push(row);

  for (let i = 1; i < panels?.rightPanel.keys.length; i++) {
    let row = [
      {
        content: panels?.rightPanel.keys[i],
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          valign: "middle",
          halign: "left",
        },
      },
      {
        content: panels?.rightPanel.values[i],
        styles: { valign: "middle", halign: "left" },
      },
    ];
    tableRows.push(row);
  }

  // Calculate the total available width of the table
  const totalPageWidth =
    printCertificateConfiguration.pageWidth -
    tableConfiguration?.margins.right * 2;
  const columnPercentages = [20, 35, 20, 25];
  // Calculate the actual column widths based on percentages
  const columnWidths = columnPercentages.map((percentage) => {
    return (totalPageWidth * percentage) / 100;
  });

  autoTable(printCertificateConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal,
    margin: tableConfiguration?.margins,
    body: tableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      lineWidth:  printCertificateConfiguration.lineWidth,
      fontSize: panels.tableTextfontSize || printCertificateConfiguration.fontSizes.h2,
      font: customFontFamily?.normalFont,
      cellPadding: panels.cellPadding || printCertificateConfiguration.cellPaddingTable
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
    },
  });

  layoutConfig.yPositionGlobal =
    printCertificateConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};

export const generateQRCodeImage = async (value) => {
  return new Promise((resolve, reject) => {
    QRCode.toDataURL(
      encodeURI(value),
      { width: 300, height: 300, 
        errorCorrectionLevel: "H" // High error correction for better scanning
      },
      (err, url) => {
        if (err) {
          reject(err);
        } else {
          resolve(url);
        }
      }
    );
  });
};

export const generateAndAddQRCodes = async (
  margin,
  qrCodeSize,
  hasSecondQRCode = true,
  showNames = true
) => {  
  const qrCode1Url = await generateQRCodeImage(
    "https://nablwp.qci.org.in/CertificateScopenew?x=W8wxwhmkH5poKpKreY0yrA==&a=NTA4Mzg="
  );

  const qrCode2Url = await generateQRCodeImage(
    `${DOMAIN}/#/certificate/print/${printcertificateConfigData.certificateId}`
  );

  const qrSpacing = 20;

  if (hasSecondQRCode && qrCode1Url) {
    printCertificateConfiguration.pdfDoc.addImage(qrCode1Url, "PNG", layoutConfig.xPositionGlobal, layoutConfig.yPositionGlobal, qrCodeSize, qrCodeSize);

    if (showNames) {
      printCertificateConfiguration.pdfDoc.setFontSize(printCertificateConfiguration.fontSizes.h4);
      printCertificateConfiguration.pdfDoc.setFont(customFontFamily?.boldFont);
      printCertificateConfiguration.pdfDoc.text("Lab QR Code", layoutConfig.xPositionGlobal + 15, layoutConfig.yPositionGlobal + qrCodeSize + 5);
    }
  }

  if (qrCode2Url) {
    printCertificateConfiguration.pdfDoc.addImage(
      qrCode2Url,
      "PNG",
      printCertificateConfiguration.pageWidth - qrCodeSize - margin,
      layoutConfig.yPositionGlobal,
      qrCodeSize,
      qrCodeSize
    );

    if (showNames) {
      printCertificateConfiguration.pdfDoc.setFontSize(printCertificateConfiguration.fontSizes.h4);
      printCertificateConfiguration.pdfDoc.setFont(customFontFamily?.boldFont);
      printCertificateConfiguration.pdfDoc.text(
        "Certificate QR Code",
        printCertificateConfiguration.pageWidth - qrCodeSize - 11,
        layoutConfig.yPositionGlobal + qrCodeSize + 5
      );
    }
  }

  layoutConfig.yPositionGlobal += qrCodeSize + qrSpacing + 15;
};

export const drawText = (
  text,
  xSize,
  ySize,
  align = "left",
  fontSize = 10,
  topSpace = 0,
  bottomSpace = 0,
  fontType
) => {
  printCertificateConfiguration.pdfDoc.setFontSize(fontSize);
  if (fontType == "normalfont") {
    printCertificateConfiguration.pdfDoc.setFont(
      customFontFamily?.normalFont,
      customFontFamily?.normal
    );
  } else {
    printCertificateConfiguration.pdfDoc.setFont(
      customFontFamily?.boldFont,
      customFontFamily?.bold
    );
  }

  let yPos = ySize + topSpace;

  // Align the text horizontally
  let textWidth = printCertificateConfiguration.pdfDoc.getTextWidth(text);
  let xPos = xSize;

  if (align === "left") {
    xPos += 1;
  } else if (align === "center") {
    xPos =
      (printCertificateConfiguration.pdfDoc.internal.pageSize.width -
        textWidth) /
      2;
  } else if (align === "right") {
    xPos =
      printCertificateConfiguration.pdfDoc.internal.pageSize.width -
      textWidth -
      xSize;
  }

  printCertificateConfiguration.pdfDoc.text(text, xPos, yPos);
  layoutConfig.yPositionGlobal = yPos + fontSize + bottomSpace;

  if (fontType == "normalfont") {
    printCertificateConfiguration.pdfDoc.setFont(
      customFontFamily?.boldFont,
      customFontFamily?.bold
    );
  } else {
    printCertificateConfiguration.pdfDoc.setFont(
      customFontFamily?.normalFont,
      customFontFamily?.normal
    );
  }
};

export const drawTextBoldAndNormal = (
  boldText,
  normalText,
  xSize,
  ySize,
  align = "left",
  fontSize = 10,
  topSpace = 0,
  bottomSpace = 0
) => {
  printCertificateConfiguration.pdfDoc.setFontSize(fontSize);

  let yPos = ySize + topSpace;
  let textWidth =
    printCertificateConfiguration.pdfDoc.getTextWidth(boldText) +
    printCertificateConfiguration.pdfDoc.getTextWidth(normalText);
  let xPos = xSize;

  if (align === "left") {
    xPos += 1;
  } else if (align === "center") {
    xPos =
      (printCertificateConfiguration.pdfDoc.internal.pageSize.width -
        textWidth) /
      2;
  } else if (align === "right") {
    xPos =
      printCertificateConfiguration.pdfDoc.internal.pageSize.width -
      textWidth -
      xSize;
  }

  // Draw the bold text first
  printCertificateConfiguration.pdfDoc.setFont(
    customFontFamily?.boldFont,
    "bold"
  );
  printCertificateConfiguration.pdfDoc.text(boldText, xPos, yPos);

  // Draw the normal text right after the bold part
  printCertificateConfiguration.pdfDoc.setFont(
    customFontFamily?.normalFont,
    customFontFamily?.normal
  );
  printCertificateConfiguration.pdfDoc.text(
    normalText,
    xPos + printCertificateConfiguration.pdfDoc.getTextWidth(boldText) + 5,
    yPos
  );

  layoutConfig.yPositionGlobal = yPos + bottomSpace;
  printCertificateConfiguration.pdfDoc.setFont(
    customFontFamily?.boldFont,
    "bold"
  );
};

export const createPDFWithSignature = async (setPageSection) => {
  await setPageSection();

  const signatureText = digitallySignConfig.signatureTextContnet;
  const signatureContent = signatureText.join("\n");
  const margin = 10;

  const pageWidth =
    printCertificateConfiguration.pdfDoc.internal.pageSize.width;
  const pageHeight =
    printCertificateConfiguration.pdfDoc.internal.pageSize.height;

  // Calculate text height
  const calculateTextHeight = (text, width, fontSize) => {
    const wrappedText = printCertificateConfiguration.pdfDoc.splitTextToSize(
      text,
      width
    );
    return wrappedText.length * fontSize * 1.2;
  };

  const textWidth = pageWidth - 2 * margin; // Allow for margins
  const textHeight = calculateTextHeight(
    signatureContent,
    textWidth,
    printCertificateConfiguration.fontSizes.h4
  );

  // Check if a new page is needed
  const remainingPageHeight =
    pageHeight -
    footerConfig.footerimgHeight -
    footerConfig.footertableHeight -
    footerConfig.footerandbodygap -
    10;

  if ( layoutConfig.yPositionGlobal + textHeight > remainingPageHeight) {
    await addNewPage();
    layoutConfig.yPositionGlobal =
      totalHeaderHeightWithCalibrationLableorNot.total + 5;
  }

  // Draw the "Digital Signature of Laboratory" in bold
  drawText(
    "Digital Signature of Laboratory:",
    layoutConfig.xPositionGlobal,
    layoutConfig.yPositionGlobal,
    "left",
    printCertificateConfiguration.fontSizes.h4,
    5,
    0,
  );

  layoutConfig.yPositionGlobal += 1; // Add spacing after the bold text

  // Draw the remarks content in normal font
  const wrappedContent = printCertificateConfiguration.pdfDoc.splitTextToSize(
    signatureContent,
    textWidth
  );
  wrappedContent.forEach((line) => {
    drawText(
      line,
      layoutConfig.xPositionGlobal,
      layoutConfig.yPositionGlobal,
      "left",
      printCertificateConfiguration.fontSizes.h4,
      0,
      0,
      "normalfont" // Use the normal font
    );
    layoutConfig.yPositionGlobal += 1;
  });

  layoutConfig.yPositionGlobal += 1; // Add final spacing
};

export const environmenentTable = async (setPageSection) => {
  await setPageSection();

  let tableRows = [];
  if (printCertificateSection.jrpmtable) {
    let PressureISValid = !isNaN(parseFloat(printcertificateConfigData.certificateDetailsConfig?.startPressure)) &&
  !isNaN(parseFloat(printcertificateConfigData.certificateDetailsConfig?.endPressure));

  let tableRows = [];
    let row = [
      {
        content: "Environmental Details",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "center",
          valign: "middle",
        },
        rowSpan: PressureISValid ? 3 : 2,
      },
      {
        content: "Temperature",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "startHumidity",
        },
      },
      {
        content: "Start",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "center",
          valign: "middle",
        },
        rowSpan: 2,
      },
      {
        content: parseEnvironment(
          printcertificateConfigData.certificateDetailsConfig?.startTemp,"°C"
        ),
        styles: {
          halign: "left",
        },
      },
      {
        content: "End",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "center",
          valign: "middle",
        },
        rowSpan: 2,
      },
      {
        content: parseEnvironment(
          printcertificateConfigData.certificateDetailsConfig?.endTemp,"°C"
        ),
        styles: { halign: "left" },
      },
      {
        content: "Difference",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "center",
          valign: "middle",
        },
        rowSpan: 2,
      },
      {
        content:
          Math.abs(
            parseFloat(
              printcertificateConfigData.certificateDetailsConfig?.startTemp
            ) -
              parseFloat(
                printcertificateConfigData.certificateDetailsConfig?.endTemp
              )
          )?.toFixed(1) + " °C" || "\u00A0",
        styles: { halign: "left" },
      },
    ];
    tableRows.push(row);
    row = [
      {
        content: "Relative Humidity",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "left",
        },
      },
      {
        content: parseEnvironment(
          printcertificateConfigData.certificateDetailsConfig?.startHumidity,"%RH"
        ),
        styles: { halign: "left" },
      },
      {
        content: parseEnvironment(
          printcertificateConfigData.certificateDetailsConfig?.endHumidity,"%RH"
        ),
        styles: { halign: "left" },
      },
      {
        content:
          Math.abs(
            parseFloat(
              printcertificateConfigData.certificateDetailsConfig?.startHumidity
            ) -
              parseFloat(
                printcertificateConfigData.certificateDetailsConfig?.endHumidity
              )
          )?.toFixed(1) + " %RH" || "\u00A0",
        styles: { halign: "left" },
      },
    ];
    tableRows.push(row);

    // Check if both pressure values are present
    if (PressureISValid) {
      row = [
        {
          content: "Pressure (mbar)",
          styles: {
            font: customFontFamily?.boldFont,
            fontStyle: customFontFamily?.bold,
            halign: "left",
          },
        },
        {
          content: (
            (
              (parseFloat(
                printcertificateConfigData.certificateDetailsConfig?.startPressure
              ) +
                parseFloat(
                  printcertificateConfigData.certificateDetailsConfig?.endPressure
                )) /
              2
            ).toFixed(1)
          ) || "\u00A0",
          styles: { halign: "left" },
          colSpan: 2
        },
        {
          content: "\u00A0", // Empty cell
          styles: { halign: "left" },
          colSpan: 4
        },
      ];

      tableRows.push(row);
    }
  } else {
  
    const row = [
      {
        content: "Environmental Conditions:",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "left",
        },
      },
      {
        content: `Temperature:`,
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "right",
        },
      },
      {
        content: parseValue(
          printcertificateConfigData.certificateDetailsConfig?.startTemp
        ) || "",
        styles: {
          halign: "left",
        },
      },
      {
        content: `Humidity:`,
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "right",
        },
      },
      {
        content: parseValue(
          printcertificateConfigData.certificateDetailsConfig?.startHumidity
        ) || "",
        styles: {
          halign: "left",
        },
      },
    ];
    tableRows.push(row);
  }
  autoTable(printCertificateConfiguration.pdfDoc, {
    startY:
      layoutConfig.yPositionGlobal -
      environmentTableConfig.spacebetweenHeadingandTable,
    margin: tableConfiguration?.margins,
    body: tableRows, // All following rows as table body
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printCertificateConfiguration.textColor,
      lineColor: printCertificateConfiguration.textColor,
      lineWidth: printCertificateConfiguration.lineWidth,
      fontSize: printCertificateConfiguration.fontSizes.h2,
      halign: "left",
      font: customFontFamily?.normalFont,
      cellPadding: environmentTableConfig.cellPadding,
    },
    tableLineColor: printCertificateConfiguration.textColor,
    tableLineWidth: printCertificateConfiguration.lineWidth,
    bodyStyles: {
      lineWidth: environmentTableConfig.innerBorder,
    },
  });

  layoutConfig.yPositionGlobal =
    printCertificateConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};

export const drawUUCTable = async (setPageSection) => {
  await setPageSection();

  const panels = UUCDetails(
    printcertificateConfigData.certificateDetailsConfig
  );
  drawText(
    panels.heading,
    layoutConfig.xPositionGlobal,
    layoutConfig.yPositionGlobal,
    panels.headingAlign,
    panels.headingFontSize,
    panels.headingTopSpace,
    panels.headingBottomSpace,
  );

  let tableRows = [];
  panels.rows.forEach(row => {
    const leftKey = row[0]?.key || "";
    const leftValue = row[0]?.value || "";
    const rightKey = row[1]?.key || "";
    const rightValue = row[1]?.value || "";
    tableRows.push([
      {
        content: String(leftKey), // Ensure content is a string
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "left",
          valign: "middle",
        },
      },
      {
        content: panels.middleColumn && String(leftValue) ? `: ${String(leftValue)}` : String(leftValue), // Ensure content is a string
        styles: { halign: "left", valign: "middle" },
      },
      {
        content: String(rightKey), // Ensure content is a string
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "left",
          valign: "middle",
        },
      },
      {
        content: panels.middleColumn && String(rightValue)  ? `: ${String(rightValue)}` : String(rightValue), // Ensure content is a string
        styles: { halign: "left", valign: "middle" },
      },
    ]);
  });

  // Calculate the total available width of the table
  const totalPageWidth =
    printCertificateConfiguration.pageWidth -
    tableConfiguration?.margins.right * 2;
  const columnPercentages = [20, 35, 20, 25];
  // Calculate the actual column widths based on percentages
  const columnWidths = columnPercentages.map((percentage) => {
    return (totalPageWidth * percentage) / 100;
  });

  // Draw the table using autoTable
  autoTable(printCertificateConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal - panels.spacebetweenHeadingandTable,
    margin: tableConfiguration?.margins,
    body: tableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printCertificateConfiguration.textColor,
      lineColor: printCertificateConfiguration.textColor,
      lineWidth: printCertificateConfiguration.lineWidth,
      fontSize: panels.tableTextfontSize,
      font: customFontFamily?.normalFont,
      cellPadding: panels.cellPadding,
    },
    tableLineColor: printCertificateConfiguration.textColor,
    tableLineWidth: printCertificateConfiguration.lineWidth,
    bodyStyles: {
        lineWidth: panels.innerBorder,
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
    },
  });
  layoutConfig.yPositionGlobal =
    printCertificateConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};

export const detailsofstandards = async (setPageSection) => {
  await setPageSection();

  const panels = MasterEQPDetails(printcertificateConfigData.standardsConfig);
  drawText(
    panels.heading,
    layoutConfig.xPositionGlobal,
    layoutConfig.yPositionGlobal,
    panels.headingAlign,
    panels.headingFontSize,
    panels.headingTopSpace,
    panels.headingBottomSpace,
  );

  let tableRows = [];
  const headerRow = panels.keys.map((key) => ({
    content: key,
    styles: {
      font: customFontFamily?.boldFont,
      fontStyle: customFontFamily?.bold,
      halign: "center",
    },
  }));
  tableRows.push(headerRow);
  panels.values.forEach((row) => {
    const dataRow = row.map((value) => ({
      content: value,
    }));
    tableRows.push(dataRow);
  });

  autoTable(printCertificateConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal - panels.spacebetweenHeadingandTable,
    margin: tableConfiguration?.margins,
    body: tableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printCertificateConfiguration.textColor,
      lineColor: printCertificateConfiguration.textColor,
      lineWidth: printCertificateConfiguration.lineWidth,
      fontSize:  panels.tableTextfontSize || printCertificateConfiguration.fontSizes.h2,
      halign: panels.tableValueAlign || "left",
      font: customFontFamily?.normalFont,
      cellPadding: panels.cellPadding || printCertificateConfiguration.cellPaddingTable
    },
  });

  layoutConfig.yPositionGlobal =
    printCertificateConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};

export const remarksTable = async (remarksCondition, setPageSection) => {
  await setPageSection();

  const pageWidth =
    printCertificateConfiguration.pdfDoc.internal.pageSize.width;
  const pageHeight =
    printCertificateConfiguration.pdfDoc.internal.pageSize.height;
  let remarksWidth = (pageWidth * 80) / 100;
  const imgWidth = (pageWidth * 15) / 100;
  const qrCodeSize = 50;
  const margin = 10;
  const isImageAvailable =
    printcertificateConfigData?.certificateDetailsConfig?.instrumentId ==
      EccentricityDiagramInstruemntID &&
    printcertificateConfigData?.certificateDetailsConfig?.datasheetImages?.split(",")?.[0];

  if (isImageAvailable) {
    remarksWidth = (pageWidth * 65) / 100;
  }

  // Calculate text height
  const calculateRemarksTextHeight = (remarks, width, fontSize) => {
    let totalHeight = 0;
    remarks.forEach((line) => {
      const wrappedText = printCertificateConfiguration.pdfDoc.splitTextToSize(
        line,
        width
      );
      totalHeight += wrappedText.length * fontSize * 1.2;
    });
    return totalHeight;
  };

  // Prepare remarks text
  const remarksText = [];
    // Define your default remarks
    let defaultRemark = `. The results reported relate only to the calibration item/s mentioned above.\n. The reported uncertainty is based on a standard uncertainty multiplied by a coverage factor of k=2, which provides a level of confidence of approximately 95%, the uncertainty of measurement has been estimated in accordance with the principles defined in the GUM, Guide to Uncertainty of Measurement, ISO, Geneva, 2008.\n. The results reflected on this certificate were entered directly on the PC.\n`;
    // Add default remarks to remarksText
    remarksText.push(defaultRemark);
  
    if (
    remarksCondition?.settingList["Compliance Status"]?.toLowerCase() === "true"
  ) {
    const complianceRemark = `The results ${
      remarksCondition?.complianceStatus == 1 ? "" : "don't"
    } comply with acceptance criteria.`;
    remarksText.push(complianceRemark);
  }

  if (remarksCondition?.calTypeSetting) {
    const calibrationOptions =
      remarksCondition?.settingList?.["CalibrationTypeOptions"]?.split(",") ||
      [];
    const reasonForCalibration =
      calibrationOptions[remarksCondition?.calibrationReason - 1] || "";
    remarksText.push(`Reason for Calibration: ${reasonForCalibration}`);
  }

  if (remarksCondition?.configuration?.remark) {
    remarksText.push(...remarksCondition.configuration.remark.split("\n"));
  }

  if (remarksCondition?.settingList["amendment"]?.toLowerCase() === "true") {
    let ISendcalibrationDate  = printcertificateConfigData.certificateDetailsConfig?.endCalibrationDate ?  `${DateisValid(printcertificateConfigData.certificateDetailsConfig?.calibrationDate)} to ${DateisValid(printcertificateConfigData.certificateDetailsConfig?.endCalibrationDate)}` : DateisValid(printcertificateConfigData.certificateDetailsConfig?.calibrationDate);

    const amendmentRemark = `
    Amendment Certificate No: ${
      printcertificateConfigData.certificateDetailsConfig?.certificateNumber
    }${
      printcertificateConfigData.certificateDetailsConfig?.revisionNumber > 0
        ? `/AMD-${printcertificateConfigData.certificateDetailsConfig?.revisionNumber}.`
        : ""
    }
  * This is the Amendment certificate of the ${
    printcertificateConfigData.certificateDetailsConfig?.certificateNumber
  } dated ${ISendcalibrationDate}, issued on ${
      printcertificateConfigData.certificateDetailsConfig?.dateOfIssue
        ? DateisValid(
            printcertificateConfigData.certificateDetailsConfig?.dateOfIssue
          )
        : DateisValid(
            printcertificateConfigData.certificateDetailsConfig?.approvedDate
          )
    }.
  * Reason for Amendment: 
  ${
    remarksCondition?.configuration?.amendmnetHistory
      ? remarksCondition?.configuration?.amendmnetHistory
      : ""
  }`;

    remarksText.push(amendmentRemark);
  }

  // Calculate remarks section height
  const remarksLabelHeight = printCertificateConfiguration.fontSizes.h4 * 1.2;
  const remarksTextHeight = calculateRemarksTextHeight(
    remarksText,
    remarksWidth + margin,
    printCertificateConfiguration.fontSizes.h4
  );
  const totalRemarksHeight = remarksLabelHeight + remarksTextHeight;
  let maxHeights = Math.max(totalRemarksHeight, qrCodeSize-5)
  // console.log(layoutConfig.yPositionGlobal, maxHeights);
  // console.log(
  //   pageHeight -
  //     footerConfig.footerimgHeight -
  //     footerConfig.footertableHeight -
  //     footerConfig.footerandbodygap
  // );
  // Check if a new page is needed
  if (
    layoutConfig.yPositionGlobal + maxHeights >
    pageHeight -
      footerConfig.footerimgHeight -
      footerConfig.footertableHeight -
      footerConfig.footerandbodygap
  ) {
    await addNewPage();
    layoutConfig.yPositionGlobal =
      totalHeaderHeightWithCalibrationLableorNot.total + 5;
  }
  let before = layoutConfig.yPositionGlobal;
  let after = 0;
  // console.log("before", layoutConfig.yPositionGlobal);

  // Draw "Remarks:" label
// if(remarksCondition?.settingList["Compliance Status"]?.toLowerCase() === "true" || remarksCondition?.configuration?.remark){
  drawText(
    "Notes:",
    layoutConfig.xPositionGlobal,
    layoutConfig.yPositionGlobal,
    "left",
    printCertificateConfiguration.fontSizes.h2,
    5,
    0
  );
// }

  layoutConfig.yPositionGlobal += remarksLabelHeight -10; 

  // Draw remarks text with wrapping
  const xPositionRemarks = layoutConfig.xPositionGlobal;
  remarksText.forEach((line) => {
    const wrappedText = printCertificateConfiguration.pdfDoc.splitTextToSize(
      line?.trim()?.replace(/\t/g, ' '),
      remarksWidth + margin // Account for padding/margin
    );
    wrappedText.forEach((wrappedLine) => {
      drawText(
        wrappedLine,
        xPositionRemarks,
        layoutConfig.yPositionGlobal,
        "left",
        printCertificateConfiguration.fontSizes.h4,
        0,
        0,
        "normalfont"
      );
      layoutConfig.yPositionGlobal += 2; // Increment position for the next line
    });
  });

  after = layoutConfig.yPositionGlobal;
  // console.log("afterremark", layoutConfig.yPositionGlobal);
  // console.log({ before, after });

  // Add optional image (20% width)
  if (isImageAvailable) {
    const datasheetImages =
      printcertificateConfigData?.certificateDetailsConfig?.datasheetImages ||
      "";
    const imageUrl = datasheetImages?.split(",")?.[0];
    const imageXPosition = xPositionRemarks + remarksWidth + 2 * margin;
    const imageYPosition = before + margin;
    const imageHeight = imgWidth;

    // Draw "Pan Position:" text above the image
    drawText(
      "Pan Position:",
      imageXPosition + 20,
      before,
      "left",
      printCertificateConfiguration.fontSizes.h2,
      5,
      0
    );

    // Add the image
    printCertificateConfiguration.pdfDoc.addImage(
      imageUrl,
      "PNG",
      imageXPosition,
      imageYPosition,
      imgWidth,
      imageHeight
    );
    // layoutConfig.yPositionGlobal += imageHeight; // Update position after adding the image
  }

  // Add QR code (20% width)  
  const qrCodeUrl = await generateQRCodeImage(
    `${DOMAIN}/#/certificate/print/${printcertificateConfigData.certificateId}`
  );

  if (qrCodeUrl) {
    const qrCodeXPosition =
      xPositionRemarks +
      remarksWidth +
      2 * margin +
      (isImageAvailable ? imgWidth : 0);
    printCertificateConfiguration.pdfDoc.addImage(
      qrCodeUrl,
      "PNG",
      qrCodeXPosition,
      before - 5,
      qrCodeSize,
      qrCodeSize
    );
    layoutConfig.yPositionGlobal += qrCodeSize; 
  }

  layoutConfig.yPositionGlobal += 5; // Final adjustment
};